<template>
  <div class="white-block-content text-center">
    <SsrHead :meta="meta" />

    <i class="aficon-question-circle" style="font-size: 150px;"></i>
    <h5>{{ t("not_found.page_not_found") }}</h5>
    <router-link to="/">{{ t("not_found.go_home") }}</router-link>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";

import SsrHead from "../components/SsrHead";

export default {
  name: "NotFound",
  components: {
    SsrHead,
  },
  setup() {
    const { t } = useI18n();

    const meta = {
      title: t("not_found.title"),
      meta: [{ name: "robots", content: "noindex, nofollow" }],
    };
    useMeta(meta);

    return {
      t,
      meta,
    };
  },
};
</script>

<style scoped></style>
